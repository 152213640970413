import React, { useContext, useEffect, useState } from 'react';
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import axiosInstance from '../../../config/axios';
import { RootContext } from '../../../App';
import refactorStructureData from '../../../helpers/refactorDataMenu';
import { Button } from "@material-tailwind/react";
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Swal from 'sweetalert2';
import Input from "@material-tailwind/react/Input";

const UpdateSlideshowBanner = () => {

  const { state, setState } = useContext(RootContext);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const param = useParams();
  const group = JSON.parse(localStorage.getItem("group"));
  const [file, setFile] = useState(null);

  const submitHandle = (event) => {

    setLoading(true);
    const token = localStorage.getItem("access_token");

    const formData = new FormData();
    formData.append('token', token);
    formData.append('group', group.wbid);
    formData.append('id', param.id);

    if (file == null) {

      Swal.fire(
        'Error!',
        'Please Add Image To Form!',
        'error'
      ).then(() => {

        history.push("/web_management/cms/slideshow_banner");

      });
    }

    formData.append('gambar', file);

    axiosInstance.post('update_baner', formData, {

      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((res) => {

      if (res.data.code === 0) {

        Swal.fire(
          'Saved!',
          'Your data is saved!',
          'success'
        ).then(() => {

          history.push("/web_management/cms/slideshow_banner");

        });
      } else {

        Swal.fire(
          'Failed!',
          'Failed Upload!',
          'warning'
        );

        console.log(res.data.message);
      }

    }).catch((err) => {

      Swal.fire(
        'Failed!',
        'Failed Upload!',
        'Warning'
      );

      console.log(err);
    })

    event.preventDefault();
  }

  const getMenu = () => {

    const token = localStorage.getItem("access_token");

    axiosInstance.post('get_menu', {

      token: token,
      group: group.wbid,

    }).then((res) => {

      setState({

        ...state,

        group: res.data.data.additional_data.group,

        menu: refactorStructureData(res.data.data.additional_data.menu),

      });

    }).catch((err) => {

      console.log(err);

    })

  }

  useEffect(() => {

    getMenu();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (

    <div>

      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>

      <div className="px-3 md:px-8 h-auto -mt-24">

        <div className="container mx-auto max-w-full">

          <div className="grid grid-cols-1 px-4 mb-16">

            <Card>

              <CardHeader color="blueGray" contentPosition="left">

                <div className='flex justify-between ' style={{ width: '900px' }}>

                  <h2 className="text-white text-2xl">Update Slide Banner</h2>

                </div>

                <p>{param.id}</p>

              </CardHeader>

              <CardBody>

                <div className='mb-1'>

                  <label>

                    Image: *.jpg, *.png, *.webp are accepted.

                  </label>

                  <br />

                  <label>

                    Size: 600x400 px.

                  </label>

                </div>

                <br />
                <form onSubmit={submitHandle}>
                  <Input
                    type="file"
                    color="lightBlue"
                    size="regular"
                    outline={true}
                    placeholder="New Image"
                    onChange={(event) => setFile(event.target.files[0])}
                  />

                  <br />

                  <Button
                    className="mt-5"
                  >
                    {loading ? 'Loading...' : 'Save'}
                  </Button>
                </form>

              </CardBody>

            </Card>

          </div>

        </div >

      </div >

    </div >

  )

}

export default UpdateSlideshowBanner;