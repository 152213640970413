import TableRtp from "components/TableRtp";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { RootContext } from "../../../App";
import refactorStructureData from "../../../helpers/refactorDataMenu";
import axiosInstance from '../../../config/axios'

export default function Rtp() {
  const group = JSON.parse(localStorage.getItem("group"));
  const history = useHistory();
  const token = localStorage.getItem("access_token");
  const { state, setState } = useContext(RootContext);
  const [rptdata, setRtpData] = useState();

  const getRtp = () => {

    if (!token) {
      //history.push("/login");
      console.log("invalid token")
    } else {

      try {
        axiosInstance("get_rtp_page", {

          method: "POST",
          data: {
            token: token,
            group: group.wbid
          },
        })
          .then((res) => {

            setState({
              ...state,
              group: res.data.data.additional_data.group,
              menu: refactorStructureData(res.data.data.additional_data.menu),
            });

            let rtpPage = res.data.data.list;

            setRtpData(rtpPage.rtppage);

            console.log("rtp url: " + rtpPage.rtppage)
          })
          .catch((err) => {

            /* localStorage.removeItem("access_token");
            history.push("/login"); */
            console.log("catch error: " + err)
          });

      } catch (error) {

        console.log("ERROR: ", error);
      }
    }
  };

  useEffect(() => {
    getRtp()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <TableRtp rtpUrl={rptdata} />
          </div>
        </div>
      </div>
    </>
  );
}
