import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Button from "@material-tailwind/react/Button";
import Icon from "@material-tailwind/react/Icon";
import InputIcon from "@material-tailwind/react/InputIcon";
import { useState, useContext } from "react";
import axiosInstance from '../config/axios';
import Swal from "sweetalert2";
import refactorStructureData from "../helpers/refactorDataMenu";
import { RootContext } from "../App";

export default function TableRtp({ rtpUrl }) {

  const group = JSON.parse(localStorage.getItem("group"));
  const token = localStorage.getItem("access_token");
  const { state, setState } = useContext(RootContext);

  const [dataurl, setDataUrl] = useState("");

  const changeUrl = ({ target }) => {

    setDataUrl(target.value);
  }

  const updateRtp = () => {

    if (dataurl === "") {

      Swal.fire('Stop', 'Please fill new url before saving', 'warning')
    } else {

      try {
        axiosInstance("update_rtp_page", {

          method: "POST",
          data: {
            token: token,
            group: group.wbid,
            rtpurl: dataurl
          },
        })
          .then((res) => {

            setState({
              ...state,
              group: res.data.data.additional_data.group,
              menu: refactorStructureData(res.data.data.additional_data.menu),
            });

            if (res.data.code === 0) {

              Swal.fire('Success', 'Update New Url Rtp Success', 'success')
                .then(function () {

                  window.location.reload();
                })
            } else {

              Swal.fire('Sory', 'Update New Url Error', 'error');
              console.log("update url rtp error: " + res.data.message);
            }
          })
          .catch((err) => {

            Swal.fire('Sory', 'Update Error', 'error');
            console.log("update url rtp error: " + err);
          });

      } catch (error) {

        console.log("ERROR: ", error);
      }
    }
  };

  return (
    <Card>
      <CardHeader color="blueGray" contentPosition="left">
        <h2 className="text-white text-2xl">Rtp Page</h2>
      </CardHeader>
      <CardBody>
        <div className="overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Rtp URL
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                  {rtpUrl}
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </CardBody>

      <CardBody>
        <div className="overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  New URL
                </th>
                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                  <InputIcon
                    type="text"
                    color="lightBlue"
                    size="sm"
                    outline={true}
                    placeholder="New URL"
                    value={dataurl}
                    onChange={changeUrl}
                    iconName="link"
                  />
                </th>
                <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left flex">
                  <Button
                    color="lightBlue"
                    buttonType="outline"
                    size="regular"
                    rounded={false}
                    block={false}
                    iconOnly={false}
                    ripple="dark"
                    onClick={updateRtp}
                  >
                    <Icon name="save" />
                    Save
                  </Button>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
}
